"use client"

import { useConfigStore } from "@/store/config-store"
import { mainFooterDownloadAppAndroidEvent, mainFooterDownloadAppIosEvent } from "@lib/amplitude/helpers"
import Col from "@ui-library/grid/Col"
import Skeleton from "@ui-library/skeleton/Skeleton"
import dynamic from "next/dynamic"
import Link from "next/link"
import { usePathname } from "next/navigation"

const AppStoreWhite = dynamic(() => import("@layout/icons/AppStoreWhite"), {
  ssr: true,
  loading: () => <Skeleton className="h-14 w-36 rounded-md" />
})
const GooglePlayWhite = dynamic(() => import("@layout/icons/GooglePlayWhite"), {
  ssr: true,
  loading: () => <Skeleton className="h-14 w-36 rounded-md" />
})

type DownloadAppsProps = {
  appStoreUrl?: string
  googlePlayUrl?: string
}

export const DownloadApps = ({ appStoreUrl, googlePlayUrl }: DownloadAppsProps) => {
  const strings = useConfigStore.getState().strings
  const pathname = usePathname()

  return (
    <Col grid={{ xs: 12, lg: 4 }}>
      <div className="inline-flex w-full justify-start lg:justify-end">
        <div>
          <p className="mx-0 mb-4 mt-0 text-[1.125rem] font-bold not-italic leading-[1.2] xl:text-[1.25rem]">
            {strings?.footer.download}
          </p>
          <Link
            href={appStoreUrl || ""}
            target="_blank"
            className="relative inline-block w-36"
            onClick={() => mainFooterDownloadAppIosEvent(pathname)}
          >
            <AppStoreWhite />
            <span className="sr-only">{strings?.footer.appstore}</span>
          </Link>

          <Link
            href={googlePlayUrl || ""}
            target="_blank"
            className="relative ml-4 inline-block w-36 max-lg:mt-4"
            onClick={() => mainFooterDownloadAppAndroidEvent(pathname)}
          >
            <GooglePlayWhite />
            <span className="sr-only">{strings?.footer.googleplay}</span>
          </Link>
        </div>
      </div>
    </Col>
  )
}
